function waterFlowReward() {
  function render_google_reward(google_adunit_full_path, timerObject, timerAction) {
    console.log("google reward init");
    console.log(google_adunit_full_path);

    window.googletag = window.googletag || {cmd: []};

    let reward_slot;
    let reward_granted = false;

    googletag.cmd.push(function() {
      reward_slot = googletag.defineOutOfPageSlot(
        google_adunit_full_path,
        googletag.enums.OutOfPageFormat.REWARDED

      ).addService(googletag.pubads());

      if (window.recart_id && window.recart_id != "0.00" ) {
        reward_slot.setTargeting('floor', window.recart_id);
      }

      googletag.enableServices();

      googletag.pubads().addEventListener('rewardedSlotReady', function(event) {
        if(event.slot !== reward_slot) return;
        console.log("Reward slot ready");
        clearTimeout(timerObject);
        event.makeRewardedVisible();
      });

      googletag.pubads().addEventListener('rewardedSlotGranted', function(event) {
        if(event.slot !== reward_slot) return;
        console.log("Reward slot granted");
        window.api.trackEvent('truereward_direct');
        timerAction();
        document.dispatchEvent(new Event('reward_granted'));
      });

      googletag.pubads().addEventListener('rewardedSlotClosed', function(event) {
        if(event.slot !== reward_slot) return;
        console.log("Reward slot closed");
        if(!reward_granted) location.reload();
      });

      googletag.pubads().addEventListener('slotRenderEnded', function(event) {
        if(event.slot !== reward_slot) return;
        if(event.isEmpty) {
          console.log("Reward slot empty");
          document.dispatchEvent(new Event('reward_granted'));
        }
      });

      googletag.display(reward_slot);
    });

  }

  function waterflow_reward(yandex_stat_id, google_adunit_full_path) {
    console.log('yarew inited');
    document.addEventListener('showManualInterstitial', function(ev) {
      console.log('yarew fired');
      window.yaContextCb=window.yaContextCb||[];
      window.yaContextCb.push(()=>{
        Ya.Context.AdvManager.render({
          "blockId": "R-A-7898983-5",
          "type": "rewarded",
          "platform": "touch",
          "statId": yandex_stat_id,
          onRender: function(data) {
            console.log('yarew show');
            window.api.trackEvent('reward_direct');
            clearTimeout(ev.detail.timerObject);
          },
          onError: function(data) {
            console.log('yarewerror', data.code);
            window.api.trackEvent('reward_error');
          },
          onRewarded: function(isRewarded) {
            if(isRewarded) {
              console.log('yarewarded');
              window.api.trackEvent('truereward_direct');
              document.dispatchEvent(new Event('reward_granted'));
              ev.detail.timerAction();
            } else {
              console.log('yarew rejected');
              location.reload();
            }
          }
        }, () => {
          console.log('yarew zero');
          window.api.trackEvent('google_after_yandex_reward');
          render_google_reward(google_adunit_full_path, ev.detail.timerObject, ev.detail.timerAction);
        })
      })
    })
  }

  function push(data) {
    console.log('push');
    console.log(data.yandex_stat_id);
    console.log(data.google_adunit_full_path);
    waterflow_reward(data.yandex_stat_id, data.google_adunit_full_path);
  }

  return {push: push}
}

// window.wfr = window.wfr || [];
// window.wfr.push({yandex_stat_id: id, google_adunit_full_path: unit_path})

function initWaterFlowReward() {
  var wfReward = waterFlowReward();

  if (Array.isArray(window.wfr)) {
    window.wfr.forEach((data)=>{wfReward.push(data)});
  }
  window.wfr = wfReward;
  return wfReward;
}

if (document.readyState == 'loading') {
  document.addEventListener("DOMContentLoaded", initWaterFlowReward);
} else {
  initWaterFlowReward();
}
